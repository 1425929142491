import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "close", "content", "submit" ]  


  onCreate(event) {
    let [data, status, xhr] = event.detail;
    this.closeTarget.textContent = "Fermer";
    this.submitTarget.remove();
    this.contentTarget.outerHTML = data.feedback;
  }
}