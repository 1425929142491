import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["entries", "pagination", "featured"]

  initialize() {
    if (this.hasEntriesTarget) {
      let options = {
        rootMargin: "200px",
      }
      this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
    }
  }

  connect() {
    if (this.hasFeaturedTarget) {
      Rails.ajax({
        type: "GET",
        url: "/jobs/featured",
        dataType: "json",
        success: (data) => {
          this.featuredTarget.innerHTML = data.entries;
        }
      })
    }
    if (this.hasEntriesTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location.href,
        dataType: "json",
        success: (data) => {
          this.entriesTarget.innerHTML = data.entries
          this.paginationTarget.innerHTML = data.pagination
        }
      })
      this.intersectionObserver.observe(this.paginationTarget)
    }
  }

  disconnect() {
    if (this.hasEntriesTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget)
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }

 onSearch(event){
    let input = event.currentTarget;
    if (input.value.length > 2 || input.value === "") {
      Rails.fire(input.closest("form"), "submit");
    }
  }
  
  onFilter(event) {
    let [data, status, xhr] = event.detail;
    this.entriesTarget.innerHTML = data.entries;
    this.paginationTarget.innerHTML = data.pagination;
  }

  onFilterApply(event) {
    let button = event.currentTarget;
    let form = button.closest("form");
    if (button.type === "reset") {
      form.reset();
      history.replaceState(null, "Offres d'emploi", "/jobs");
      Rails.ajax({
        type: "GET",
        url: "/jobs",
        dataType: "json",
        success: (data) => {
          this.entriesTarget.innerHTML = data.entries
          this.paginationTarget.innerHTML = data.pagination
        }
      })
    } else {
      Rails.fire(form, "submit");
    }
  }
  
  onSuccess(event){
    let [data, status, xhr] = event.detail;
    this.currentProduct.outerHTML = xhr.response;
    this.currentProduct = null;
  }
  
  onError(event) {
    let [data, status, xhr] = event.detail;
    document.getElementById("flash").innerHTML = xhr.response;
    this.currentProduct = null;
  }
  
}