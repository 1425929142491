import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["posts", "form"]

  connect() {
    if (this.hasPostsTarget) {
      Rails.ajax({
        type: "GET",
        url: "/" + this.postsTarget.dataset.postable + "/posts",
        dataType: "json",
        success: (data) => {
          if (data.entries != null) {
            this.postsTarget.innerHTML = data.entries;
          }
          if (data.form != null) {
            this.formTarget.innerHTML = data.form;
          }
        }
      })
    }
  }

  onCreate(event) {
    let [data, status, xhr] = event.detail;
    this.postsTarget.insertAdjacentHTML("afterbegin", xhr.response)
    this.formTarget.getElementsByTagName("form")[0].reset();
  }

  onError(event) {

  }
}