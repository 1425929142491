import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["form", "feedback", "errors", "related", "positions", "educations", "volunteerWorks", "reviews"]

  connect() {
    let url = window.location.href.split("#")[0]
    if (this.hasPositionsTarget) {
      Rails.ajax({
        type: "GET",
        url: url + "/positions",
        dataType: "json",
        success: (data) => {
          this.positionsTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
    if (this.hasEducationsTarget) {
      Rails.ajax({
        type: "GET",
        url: url + "/educations",
        dataType: "json",
        success: (data) => {
          this.educationsTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
    if (this.hasVolunteerWorksTarget) {
      Rails.ajax({
        type: "GET",
        url: url + "/volunteer_works",
        dataType: "json",
        success: (data) => {
          this.volunteerWorksTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
    if (this.hasRelatedTarget) {
      Rails.ajax({
        type: "GET",
        url: url + "/related",
        dataType: "json",
        success: (data) => {
          this.relatedTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
    if (this.hasReviewsTarget) {
      Rails.ajax({
        type: "GET",
        url: url + "/reviews",
        dataType: "json",
        success: (data) => {
          this.reviewsTarget.innerHTML = data.reviews;
        }
      })
    }
  }

  onGetForm(event){
    let formType = event.currentTarget.dataset.formType;
    let path = event.currentTarget.dataset.path;
    if (formType === "edit") {
      this.currentModel = document.getElementById(event.currentTarget.dataset.modelId);
    } else if (formType === "new") {
      this.modelType =  event.currentTarget.dataset.modelType;
    }
    Rails.ajax({
      type: "GET",
      url:  "/" + path + "/" + formType,
      dataType: "json",
      success: (data) => {
        this.formTarget.innerHTML = data.form;
        this.formTarget.scrollIntoView({
          behavior: "smooth"
        });
      }
    })
  }

  onCloseForm(event) {
    this.formTarget.innerHTML = null;
    this.currentRow = null;
    window.scroll({
     top: 0, 
     left: 0, 
     behavior: "smooth"
    });
  }

  onCreate(event) {
    let [data, status, xhr] = event.detail;
    let element_list = document.querySelectorAll("[data-profile-target='"+ this.modelType + "s']")[0]
    element_list.insertAdjacentHTML("beforeend", xhr.response);
    
    this.modelType = null;
    this.formTarget.innerHTML = null;
  }

  onBeforeAction(event) {
    this.currentModel = document.getElementById(event.currentTarget.dataset.modelId);
  }

  onChange(event) {
    let form = event.currentTarget.closest("form");
    this.currentModel = document.getElementById(form.dataset.modelId) || document.getElementById(event.currentTarget.dataset.modelId);
    Rails.fire(form, "submit");
  }

  onUpdate(event) {
    let [data, status, xhr] = event.detail;
    if (this.currentModel != null) {
      this.currentModel.outerHTML = xhr.response;
      
      this.currentModel = null;
      this.modelType = null;
      this.formTarget.innerHTML = null;
    }
  }

  onDestroy(event) {
    let [data, status, xhr] = event.detail;
    if (this.currentModel != null) {
      this.currentModel.remove();
      this.currentModel = null;
    }
  }

  onError(event) {
    let [data, status, xhr] = event.detail;
    this.currentModel = null;
    this.errorsTarget.innerHTML = xhr.response;
  }

}