import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  
  connect() {
    var tagify = new Tagify (this.element.querySelector(".tagify"), {
      whitelist : [],
      dropdown : {
        classname     : "bg-green-200",
        enabled       : 0,              // show the dropdown immediately on focus
        maxItems      : 5,
        position      : "text",         // place the dropdown near the typed text
        closeOnSelect : false,          // keep the dropdown open after selecting a suggestion
        highlightFirst: true
      },
      texts: {
        empty      : "empty",
        exceed     : "number of tags exceeded",
        pattern    : "pattern mismatch",
        duplicate  : "already exists",
        notAllowed : "not allowed"
      },
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    });
    var element = this.element;
    var controller= new AbortController();
    tagify.on("input", function(event) {
      var value = event.detail.value
      tagify.settings.whitelist = null // reset the whitelist

      //https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
      controller && controller.abort();
      controller = new AbortController();

      // show loading animation and hide the suggestions dropdown
      tagify.loading(true).dropdown.hide();

      Rails.ajax({
        type: "GET",
        url: "/tags?context=" + element.dataset.tagsContext + "&query=" + value,
        dataType: "json",
        success: (data) => {
          tagify.settings.whitelist = data.tags.split(",") // update inwhitelist Array in-place
          tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
        }
      })
    });
  }
}