import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["comments", "related"]

  connect() {
    if (this.hasCommentsTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location + "/reviews",
        dataType: "json",
        success: (data) => {
          this.commentsTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
    if (this.hasRelatedTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location + "/related",
        dataType: "json",
        success: (data) => {
          this.relatedTarget.insertAdjacentHTML("afterbegin", data.entries);
        }
      })
    }
  }

}