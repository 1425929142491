import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "refreshIcon", "form", "feedback", "errors", "records", "pagination", "importForm", "importErrors", "modalDismiss" ]  

  initialize() {
    /*let options = {
      rootMargin: "200px",
    }
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)*/
  }

  connect() {
    if (this.hasRecordsTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location.pathname,
        dataType: "json",
        success: (data) => {
          this.recordsTarget.innerHTML = data.records;
          if (data.page_count > 1 ) {
            this.paginationTarget.innerHTML = data.pagination;
          }
        }
      })
      //this.intersectionObserver.observe(this.paginationTarget)
    }
  }

  disconnect() {
    //this.intersectionObserver.unobserve(this.paginationTarget)
  }

  /*processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }*/

  onBeforeAction(event) {
    this.currentRow = event.currentTarget.closest("tr");
  }

  onChange(event) {
    let input = event.currentTarget;
    this.currentRow = input.closest("tr");
    Rails.fire(input.closest("form"), "submit");
  }

  onRefresh() {
    this.refreshIconTarget.classList.add("animate-spin");
    Rails.ajax({
      type: "GET",
      url: window.location.pathname,
      dataType: "json",
      success: (data) => {
        this.recordsTarget.innerHTML = data.records;
        if (data.page_count > 1 ) {
          this.paginationTarget.innerHTML = data.pagination;
        }
      }
    })
    this.refreshIconTarget.classList.remove("animate-spin");
  }

  onFilter(event){
    let input = event.currentTarget;
    if (input.value.length > 2 || input.value === "") {
      Rails.fire(input.closest("form"), "submit");
    }
  }

  onSearch(event) {
    let [data, status, xhr] = event.detail;
    this.recordsTarget.innerHTML = data.records;
    if (data.page_count > 1 ) {
      this.paginationTarget.innerHTML = data.pagination;
    }
  }

  onGetForm(event){
    let formType = event.currentTarget.dataset.formType;
    let path = event.currentTarget.dataset.path;
    if (formType === "edit") {
      this.currentRow = event.currentTarget.closest("tr") || document.getElementById(event.currentTarget.dataset.productId);
    }
    Rails.ajax({
      type: "GET",
      url:  "/" + path + "/" + formType,
      dataType: "json",
      success: (data) => {
        this.formTarget.innerHTML = data.form;
        this.formTarget.scrollIntoView({
          behavior: "smooth"
        });
      }
    })
  }

  onCloseForm(event) {
    this.formTarget.innerHTML = null;
    this.currentRow = null;
    window.scroll({
     top: 0, 
     left: 0, 
     behavior: "smooth"
    });
  }

  onCreate(event) {
    let [data, status, xhr] = event.detail;

    let empty_row = document.getElementById("empty-records");
    if ( empty_row != null ) {
      empty_row.remove();
    }
    
    this.recordsTarget.insertAdjacentHTML("afterbegin", data.record);
    this.feedbackTarget.innerHTML = data.feedback;
    
    this.formTarget.innerHTML = null;
  }

  onUpdate(event) {
    let [data, status, xhr] = event.detail;
    this.feedbackTarget.innerHTML = data.feedback;
    this.currentRow.outerHTML = data.record;

    this.formTarget.innerHTML = null;
    this.currentRow = null;
  }

  onDestroy(event) {
    let [data, status, xhr] = event.detail;
    this.currentRow.remove();
    this.currentRow = null;
  }

  onDismissFeedback(event) {
    this.feedbackTarget.innerHTML = null;
  }

  onError(event) {
    let [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = data.errors;
    this.errorsTarget.scrollIntoView({
      behavior: "smooth"
    });
  }

  onImport(event) {
    let [data, status, xhr] = event.detail;
    this.recordsTarget.innerHTML = xhr.response;
    this.modalDismissTarget.click();
    this.importFormTarget.reset();
    this.importErrorsTarget.innerHTML = null;
  }

  onImportError(event) {
    let [data, status, xhr] = event.detail;
    this.importErrorsTarget.innerHTML = xhr.response;
  }
}