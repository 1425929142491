import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  
  static targets = ["access", "requests", "searchRecipient"]

  connect() {
    if (this.hasRequestsTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location.href + "/access_requests",
        dataType: "json",
        success: (data) => {
            this.requestsTarget.innerHTML = data.entries;
        }
      })
    }
    if(this.hasSearchRecipientTarget) {
      var tagTemplate = function(tagData) {
        return `<tag title="${(tagData.name)}"
                contenteditable='false'
                spellcheck='false'
                tabIndex="-1"
                class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
                ${this.getAttributes(tagData)}>
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div class="flex items-center space-x-2">
              <img onerror="this.style.visibility='hidden'" class="h-5 w-5 rounded-full" src="${tagData.avatar}">
              <span class='tagify__tag-text'>${tagData.name}</span>
            </div>
        </tag>`
      };
      var suggestionTemplate = function(tagData) {
        return `<div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${tagData.class ? tagData.class : ""}'
            tabindex="0"
            role="option">
            <div class="flex items-center">
              <img src="${tagData.avatar}" alt="${tagData.name}" class="flex-shrink-0 h-6 w-6 rounded-full">
              <span class="font-normal ml-3 block truncate">${tagData.name}</span>
            </div>
          </div>`
      };
      var wrapperTemplate = function(input, _s) {
        return `<tags class="${_s.classNames.namespace} ${_s.mode ? `${_s.classNames[_s.mode + "Mode"]}` : ""} ${input.className}"
                  ${_s.readonly ? 'readonly' : ''}
                  ${_s.disabled ? 'disabled' : ''}
                  ${_s.required ? 'required' : ''}
                  tabIndex="-1">
          <span ${!_s.readonly && _s.userInput ? 'contenteditable' : ''} tabIndex="0" data-placeholder="${_s.placeholder || '&#8203;'}" aria-placeholder="${_s.placeholder || ''}"
              class="${_s.classNames.input}"
              role="textbox"
              aria-autocomplete="both"
              aria-multiline="${_s.mode=='mix'?true:false}"></span>
          </tags>`
      };
      //https://preview.keenthemes.com/metronic8/demo1/documentation/forms/tagify.html
      var element = this.searchRecipientTarget;
      var tagify = new Tagify (element, {
        tagTextProp: "name", // very important since a custom template is used with this property as text. allows typing a "value" or a "name" to match input with whitelist
        whitelist : [],
        dropdown : {
          classname     : "bg-green-200",
          enabled       : 0,              // show the dropdown immediately on focus
          maxItems      : 5,
          position      : "text",         // place the dropdown near the typed text
          closeOnSelect : false,          // keep the dropdown open after selecting a suggestion
          highlightFirst: true,
          searchKeys: ['name']  // very important to set by which keys to search for suggesttions when typing
        },
        texts: {
          empty      : "empty",
          exceed     : "number of tags exceeded",
          pattern    : "pattern mismatch",
          duplicate  : "already exists",
          notAllowed : "not allowed"
        },
        templates: {
          tag: tagTemplate,
          dropdownItem: suggestionTemplate,
          wrapper: wrapperTemplate
        },
        originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
      });
      var controller= new AbortController();
      tagify.on("input", function(event) {
        var value = event.detail.value
        if (value.length >= 3) {
          tagify.settings.whitelist = null // reset the whitelist
    
          //https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
          controller && controller.abort();
          controller = new AbortController();
    
          // show loading animation and hide the suggestions dropdown
          tagify.loading(true).dropdown.hide();
    
          Rails.ajax({
            type: "GET",
            url: "/profiles/suggest?query=" + value,
            dataType: "json",
            success: (data) => {
              tagify.settings.whitelist = data; // update in whitelist Array in-place
              tagify.loading(false).dropdown.show(value) // render the suggestions dropdown
            }
          })
        }
      });
    }
  }

  onSendRequests(event) {
    let [data, status, xhr] = event.detail;
    this.element.reset();
  }

  onSendError(event) {
    let [data, status, xhr] = event.detail;
    this.feedbackTarget.innerHTML = xhr.response;
  }

  onAccessRequest(event) {
    let [data, status, xhr] = event.detail;
    this.accessTarget.innerHTML = xhr.response;
  }

  onBeforeRequest(event) {
    let form = event.currentTarget.closest("form");
    this.currentRequest = event.currentTarget.closest("li");
    Rails.fire(form, "submit");
  }

  onRequestUpdate(event) {
    let [data, status, xhr] = event.detail;
    this.currentRequest.outerHTML = xhr.response;
    this.currentRequest = null;
  }

  onError(event) {
    
  }
  
}