import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["articles", "top"]
  
  connect() {
    if (this.hasTopTarget) {
      this.topTarget.classList.add("hidden");
    }
    if(this.hasArticlesTarget) {
      Rails.ajax({
        type: "GET",
        url: "/articles/featured",
        dataType: "json",
        success: (data) => {
          this.articleList = data.entries;
          this.articlesTarget.insertAdjacentHTML("afterbegin", this.articleList);
        }
      })
    }
  }

  onWindowScroll(event) {
    if (this.hasTopTarget){
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        this.topTarget.classList.remove("hidden");
      } else {
        this.topTarget.classList.add("hidden");
      }
    }
  }

  onScrollToTop(event) {
    if (this.hasTopTarget) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.topTarget.classList.add("hidden");
    }
  }

  onSearch(event){
    let input = event.currentTarget;
    if (input.value.length > 2) {
      Rails.fire(input.closest("form"), "submit");
    } else if(input.value === "") {
      this.articlesTarget.innerHTML = this.articleList ;
    }
  }
  
  onFilter(event) {
    let [data, status, xhr] = event.detail;
    this.articlesTarget.innerHTML = data.entries;
  }

  onError(event) {
    let [data, status, xhr] = event.detail;
    document.getElementById("flash").innerHTML = xhr.response;
  }
  
}