import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "question", "index", "next", "previous", "submit"]

  initialize() {
    this.indexTarget.textContent = 1;
    this.submitTarget.classList.add("hidden");
    this.showCurrentQuestion();
  }

  next(event) {
    this.indexTarget.textContent = Number(this.indexTarget.textContent) + 1;
    Rails.fire(event.currentTarget.closest("form"), "submit");
    this.showCurrentQuestion();
  }

  previous(event) {
    this.indexTarget.textContent = Number(this.indexTarget.textContent) - 1;
    this.showCurrentQuestion();
  }

  showCurrentQuestion() {
    this.questionTargets.forEach((element, index) => {
      if (index != Number(this.indexTarget.textContent) - 1) {
        element.classList.add("hidden");
        element.querySelectorAll("input, textarea").forEach((input) => {
          input.disabled = true;
        })
      } else {
        element.classList.remove("hidden");
        element.querySelectorAll("input, textarea").forEach((input) => {
          input.disabled = false;
        })
      }
    })
    if (Number(this.indexTarget.textContent) == this.element.dataset.total) {
      this.submitTarget.classList.remove("hidden");
      this.nextTarget.classList.add("hidden");
    }
    else {
      if (Number(this.indexTarget.textContent) == 1) {
        this.previousTarget.classList.add("invisible");
        this.nextTarget.classList.remove("hidden");
        this.submitTarget.classList.add("hidden");
      }
      else {
        if (Number(this.indexTarget.textContent) > 1) {
          this.previousTarget.classList.remove("invisible");
          this.nextTarget.classList.remove("hidden");
          this.submitTarget.classList.add("hidden");
        }
      }
    }
  }
}