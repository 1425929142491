import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "fileName" ]  

  connect() {
  }
  
  onFileUpload(event){
    if (event.currentTarget.value != null) {
      this.fileNameTarget.innerHTML = Array.from(event.currentTarget.files).map(file => file.name).join(", ");
    } 
    else {
      this.fileNameTarget.innerHTML = null;
    }
  }
}