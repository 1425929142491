import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["entries", "pagination"]

  initialize() {
    if (this.hasEntriesTarget) {
      let options = {
        rootMargin: "200px",
      }
      this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
    }
  }

  connect() {
    if (this.hasEntriesTarget) {
      Rails.ajax({
        type: "GET",
        url: window.location.href,
        dataType: "json",
        success: (data) => {
          this.entriesTarget.innerHTML = data.entries
          this.paginationTarget.innerHTML = data.pagination
        }
      })
      
      this.intersectionObserver.observe(this.paginationTarget)
    }
  }

  disconnect() {
    if (this.hasEntriesTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget)
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }
}