import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = ["comments", "form", "errors"]

  connect() {
    Rails.ajax({
      type: "GET",
      url: "/" + this.commentsTarget.dataset.reviewable + "/reviews",
      dataType: "json",
      success: (data) => {
        if(data.entries != null) {
          this.commentsTarget.innerHTML = data.entries;
        }
        if(data.form != null) {
          this.formTarget.innerHTML = data.form;
        }
      }
    })
  }

  onCreate(event){
    let [data, status, xhr] = event.detail;
    this.formTarget.getElementsByTagName("form")[0].reset();
    this.commentsTarget.insertAdjacentHTML("beforeend", xhr.response);
    this.errorsTarget.innerHTML = null;
  }
  
  onError(event) {
    let [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }
  
}